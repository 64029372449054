import React from 'react'

function Info() {

  return (
    <div>
            Info
    </div>
  )
}
export default Info
