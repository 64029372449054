import React from 'react'

function Projects() {
  return (
    <div>
            Projects
    </div>
  )

}
export default Projects
